import React, { useContext, useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useHttpClient } from '../common/hooks/http-hook';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, TextField, Link, Typography } from '@material-ui/core';
import ErrorModal from '../common/UI/ErrorModal';
import LoadingSpinner from '../common/UI/LoadingSpinner';
import {
  Facebook as FacebookIcon,
  Google as GoogleIcon,
  Apple as AppleIcon
} from '../common/UI/icons';
import { AuthContext } from '../common/context/auth-context';
import { useConfirmEmail } from '../common/hooks/confirmemail-hook';
import { useForm } from '../common/hooks/form-hook';
import backgroundImage from '../../public/images/vectors/Original.png';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.background.light,

    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.primary,
    fontWeight: 300,
    marginTop: theme.spacing(90)
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 75,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const SignIn = () => {
  const auth = useContext(AuthContext);
  if (auth.isLoggedIn) auth.logout();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const classes = useStyles();
  const { hasError, handleChange, formState } = useForm(schema);
  const [providerLogin, setProviderLogin] = useState(false);
  const [emailVal, setEmailVal] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  const { confirmEmail, clearConfirmEmail } = useConfirmEmail();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('prov')) setProviderLogin(true);
    if (searchParams.get('email')) {
      setEmailVal(searchParams.get('email'));
    }
  }, [location.search]);

  useEffect(() => {
    if (emailVal) {
      const email = document.querySelector("input[name='email']");
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value'
      ).set;
      nativeInputValueSetter.call(email, 'is working');
      const inputEvent = new Event('input', { bubbles: true });
      email.dispatchEvent(inputEvent);
    }
  }, [emailVal]);

  const handleSignIn = async (event) => {
    event.preventDefault();
    const redirectPath = new URLSearchParams(location.search).get('redirect');
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/api/users/login',
        'POST',
        JSON.stringify({
          email: formState.values.email,
          password: formState.values.password
        }),
        {
          'Content-Type': 'application/json'
        }
      );

      console.log(responseData.userId);
      if (responseData.token) {
        auth.login(
          responseData.userId,
          responseData.email,
          responseData.tenant,
          responseData.token,
          null,
          true,
          responseData.status,
          responseData.accountableAdmin,
          responseData.siteAdmin,
          responseData.harambe,
          responseData.program,
          responseData.provider,
          responseData.programProvider,
          responseData.supervisor
        );
        if (redirectPath) {
          navigate(`/${redirectPath}`);
        } else {
          navigate('/dashboard');
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className={classes.root}>
      {confirmEmail && (
        <ErrorModal
          open={!!confirmEmail}
          title="Email Confirmation"
          alert={confirmEmail}
          clearError={clearConfirmEmail}
        />
      )}
      {error && (
        <ErrorModal open={!!error} title="Error Signing Up" alert={error} clearError={clearError} />
      )}
      {auth.error && (
        <ErrorModal
          open={!!auth.error}
          title="Error Signing Up"
          alert={auth.error}
          clearError={auth.clearError}
        />
      )}
      {isLoading && <LoadingSpinner open={isLoading} />}
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant="h1" />
              <div className={classes.person}>
                <Typography className={classes.name} variant="body1" />
                <Typography className={classes.bio} variant="body2" />
              </div>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader} />
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSignIn}>
                {!providerLogin && (
                  <Typography className={classes.title} variant="h2">
                    Sign in
                  </Typography>
                )}
                {providerLogin && (
                  <Typography className={classes.title} variant="h2">
                    Care Team Member Sign in
                  </Typography>
                )}
                {providerLogin && (
                  <Typography color="textSecondary" gutterBottom>
                    <br /> Sign in with your email address, or{' '}
                    <Link component={RouterLink} to="/ForgotPassword" variant="h6">
                      Reset Your Password
                    </Link>
                  </Typography>
                )}
                {!providerLogin && (
                  <Typography color="textSecondary" gutterBottom>
                    Sign in with Facebook or Google
                  </Typography>
                )}
                {!providerLogin && (
                  <Grid className={classes.socialButtons} container spacing={2} justify="center">
                    <Grid item>
                      <Button
                        color="secondary"
                        href={process.env.REACT_APP_BACKEND_URL + '/api/users/fb/auth'}
                        size="large"
                        variant="contained">
                        <FacebookIcon className={classes.socialIcon} />
                        Login with Facebook
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        href={process.env.REACT_APP_BACKEND_URL + '/api/users/google/auth'}
                        size="large"
                        variant="contained">
                        <GoogleIcon className={classes.socialIcon} />
                        Login with Google
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        style={{ background: 'black', color: 'white' }}
                        href={process.env.REACT_APP_BACKEND_URL + '/api/users/apple/auth'}
                        size="large"
                        variant="contained">
                        <AppleIcon className={classes.socialIcon} />
                        Login with Apple
                      </Button>
                    </Grid>
                  </Grid>
                )}
                {!providerLogin && (
                  <Typography
                    align="center"
                    className={classes.sugestion}
                    color="textSecondary"
                    variant="body1">
                    or login with your email address
                  </Typography>
                )}
                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={hasError('email') ? formState.errors.email[0] : null}
                  label="Email address"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || emailVal || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={hasError('password') ? formState.errors.password[0] : null}
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ''}
                  variant="outlined"
                />
                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained">
                  Sign in now
                </Button>
                {!providerLogin && (
                  <Typography color="textSecondary" variant="body1">
                    Don't have an account?{' '}
                    <Link component={RouterLink} to="/sign-up" variant="h6">
                      Sign up
                    </Link>
                  </Typography>
                )}
                <Typography color="textSecondary" variant="body1">
                  {' '}
                  Forgot your password?{' '}
                  <Link component={RouterLink} to="/ForgotPassword" variant="h6">
                    Reset Password
                  </Link>
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SignIn;
