import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import { AuthContext } from '../../../common/context/auth-context';
import formatIntakeData from '../../../util/formatIntake';

interface IntakeProps {
  open: boolean;
  close: () => void;
  id: string;
  name: string;
}

interface Address {
  street: string;
  street2?: string;
  city: string;
  state: string;
  zipCode: string;
}

interface PersonalInfo {
  email: string;
  phone: string;
  dateOfBirth: string;
  pronouns: string;
  sexualOrientation: string;
  address: Address;
}

interface RecoveryInfo {
  isCurrentlyAbstinent: boolean;
  lastUseDate: string;
  substancesOfChoice: string[];
  medications: string[];
  supportTypes: string[];
  currentServices: string[];
  residentialDischargeDate: string;
  selfCareDescription: string;
  selfCareImprovement: string;
  physicalHealthGoals: string;
  exerciseRoutineActive: boolean;
  exerciseRoutineTimesPerWeek?: number;
  meditationRoutineActive: boolean;
  meditationRoutineDaysPerWeek?: number;
  attendsPeerSupport: boolean;
  peerSupportDetails: string;
  occupation: string;
  occupationalGoals: string;
  familyDynamicGoals: string;
  accountableGoals: string;
  additionalInformation: string;
}

interface EmergencyContact {
  firstName: string;
  lastName: string;
  phone: string;
}

interface IntakeFormData {
  personalInfo: PersonalInfo;
  recoveryInfo: RecoveryInfo;
  emergencyContact: EmergencyContact;
}

const Intake: React.FC<IntakeProps> = (props) => {
  const auth = useContext(AuthContext);
  const [intakeForm, setIntakeForm] = useState<IntakeFormData | string[] | null>(null);

  const handleClose = (): void => {
    props.close();
  };

  const getIntake = useCallback(async (): Promise<void> => {
    try {
      const responseData = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/accountable/getintake`,
        {
          method: 'POST',
          body: JSON.stringify({
            id: props.id
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`
          }
        }
      );

      if (responseData) {
        const intakeForm = await responseData.json();
        // Check if personalInfo key exists (only exists in new format)
        if (intakeForm?.intake?.personalInfo) {
          // New format via mobile app
          setIntakeForm(intakeForm.intake);
        } else {
          // old format
          setIntakeForm(formatIntakeData(Object.entries(intakeForm.intake)));
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [props.id, auth.token]);

  useEffect(() => {
    console.log('getting intake');
    getIntake();
  }, [getIntake]);

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`;
  };

  const styles = {
    section: {
      backgroundColor: '#f8f9fa',
      borderRadius: '8px',
      padding: '24px',
      marginBottom: '24px',
      width: '100%'
    },
    question: {
      color: '#1a1a1a',
      fontSize: '16px',
      fontWeight: 600,
      marginBottom: '8px'
    },
    answer: {
      color: '#4a4a4a',
      fontSize: '16px',
      backgroundColor: 'white',
      padding: '12px 16px',
      borderRadius: '4px',
      border: '1px solid #e0e0e0'
    },
    sectionHeader: {
      fontSize: '20px',
      fontWeight: 700,
      color: '#2c3e50',
      marginBottom: '20px',
      borderBottom: '2px solid #3f51b5',
      paddingBottom: '8px'
    }
  };

  const QuestionAnswer = ({ question, answer }: { question: string; answer: React.ReactNode }) => (
    <div style={{ marginBottom: '20px' }}>
      <div style={styles.question}>{question}</div>
      <div style={styles.answer}>
        {typeof answer === 'string' ? answer.replace(/other:\s*/gi, '') : answer}
      </div>
    </div>
  );

  const Section = ({ title, children }: { title: string; children: React.ReactNode }) => (
    <div style={styles.section}>
      <div style={styles.sectionHeader}>{title}</div>
      {children}
    </div>
  );

  const renderNewIntakeFormat = (intake: IntakeFormData) => {
    return (
      <div style={{ width: '100%', padding: '16px' }}>
        <Section title="Personal Information">
          <QuestionAnswer question="Email Address" answer={intake.personalInfo.email} />
          <QuestionAnswer question="Phone Number" answer={intake.personalInfo.phone} />
          <QuestionAnswer
            question="Date of Birth"
            answer={formatDate(intake.personalInfo.dateOfBirth)}
          />
          <QuestionAnswer question="Pronouns" answer={intake.personalInfo.pronouns} />
          <QuestionAnswer
            question="Sexual Orientation"
            answer={intake.personalInfo.sexualOrientation}
          />
          <QuestionAnswer
            question="Address"
            answer={
              <>
                {`${intake.personalInfo.address.street} ${intake.personalInfo.address.street2 || ''}`}
                <br />
                {`${intake.personalInfo.address.city}, ${intake.personalInfo.address.state} ${intake.personalInfo.address.zipCode}`}
              </>
            }
          />
        </Section>

        <Section title="Recovery History">
          <QuestionAnswer
            question="Are you currently abstinent from non-prescribed mind-altering substances?"
            answer={intake.recoveryInfo.isCurrentlyAbstinent ? 'Yes' : 'No'}
          />
          <QuestionAnswer
            question="When was your last use of non-prescribed mind altering substances?"
            answer={formatDate(intake.recoveryInfo.lastUseDate)}
          />
          <QuestionAnswer
            question="What are your substances of choice?"
            answer={intake.recoveryInfo.substancesOfChoice?.join(', ')}
          />
          <QuestionAnswer
            question="Are you on any medications to help treat your mental health or addiction?"
            answer={intake.recoveryInfo.medications?.join(', ')}
          />
        </Section>

        <Section title="Treatment Services">
          <QuestionAnswer
            question="What type of support have you received in the last 6 months?"
            answer={intake.recoveryInfo.supportTypes?.join(', ')}
          />
          <QuestionAnswer
            question="What services are you currently engaged with?"
            answer={intake.recoveryInfo.currentServices?.join(', ')}
          />
          {intake.recoveryInfo.residentialDischargeDate && (
            <QuestionAnswer
              question="Residential Discharge Date"
              answer={formatDate(intake.recoveryInfo.residentialDischargeDate)}
            />
          )}
        </Section>

        <Section title="Self Care">
          <QuestionAnswer
            question="What does self care look like to you?"
            answer={intake.recoveryInfo.selfCareDescription}
          />
          <QuestionAnswer
            question="If there was one thing you could improve around self care, what would it be?"
            answer={intake.recoveryInfo.selfCareImprovement}
          />
          <QuestionAnswer
            question="Do you have any physical health goals you are working towards?"
            answer={intake.recoveryInfo.physicalHealthGoals}
          />
          <QuestionAnswer
            question="Is exercise part of your self care routine?"
            answer={
              intake.recoveryInfo.exerciseRoutineActive
                ? `Yes - ${intake.recoveryInfo.exerciseRoutineTimesPerWeek} times per week`
                : 'No'
            }
          />
          <QuestionAnswer
            question="Is meditation part of your self care routine?"
            answer={
              intake.recoveryInfo.meditationRoutineActive
                ? `Yes - ${intake.recoveryInfo.meditationRoutineDaysPerWeek} days per week`
                : 'No'
            }
          />
        </Section>

        <Section title="Support & Goals">
          <QuestionAnswer
            question="Do you attend 12 step meetings, or other peer support programs?"
            answer={intake.recoveryInfo.attendsPeerSupport ? 'Yes' : 'No'}
          />
          {intake.recoveryInfo.peerSupportDetails && (
            <QuestionAnswer
              question={
                intake.recoveryInfo.attendsPeerSupport
                  ? 'Tell us about your program. How frequently you attend, what meetings, if you have a sponsor, or if there is anything else we can help with.'
                  : 'Is this something you are interested in exploring? Why or why not?'
              }
              answer={intake.recoveryInfo.peerSupportDetails}
            />
          )}
          <QuestionAnswer question="Occupation" answer={intake.recoveryInfo.occupation} />
          <QuestionAnswer
            question="What goals do you have around your occupational life?"
            answer={intake.recoveryInfo.occupationalGoals}
          />
        </Section>

        <Section title="Personal Reflections">
          <QuestionAnswer
            question="Are there any current family dynamic goals you are working towards?"
            answer={intake.recoveryInfo.familyDynamicGoals}
          />
          <QuestionAnswer
            question="What do you want to accomplish while enrolled in Accountable?"
            answer={intake.recoveryInfo.accountableGoals}
          />
          <QuestionAnswer
            question="Anything else we haven't covered that you would want us to know?"
            answer={intake.recoveryInfo.additionalInformation}
          />
        </Section>

        <Section title="Emergency Planning">
          <QuestionAnswer
            question="Emergency Contact Name"
            answer={`${intake.emergencyContact.firstName} ${intake.emergencyContact.lastName}`}
          />
          <QuestionAnswer
            question="Emergency Contact Phone"
            answer={intake.emergencyContact.phone}
          />
        </Section>
      </div>
    );
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
      PaperProps={{
        sx: { p: 2 }
      }}>
      <DialogTitle sx={{ pb: 2 }}>{props.name}&apos;s Intake</DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
          {intakeForm &&
            (Array.isArray(intakeForm) ? (
              <List sx={{ width: '100%' }}>
                {intakeForm.map((val) => {
                  if (val) {
                    const newval = val.split(':');
                    return (
                      <ListItem
                        key={newval[0]}
                        sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                        <ListItemText
                          primary={newval[0]}
                          secondary={newval[1]}
                          primaryTypographyProps={{
                            sx: { fontWeight: 'bold' }
                          }}
                        />
                      </ListItem>
                    );
                  }
                  return null;
                })}
              </List>
            ) : (
              renderNewIntakeFormat(intakeForm as IntakeFormData)
            ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ pt: 2 }}>
        <Button
          onClick={handleClose}
          variant="contained"
          color="primary"
          sx={{ minWidth: 100 }}
          autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Intake;
