const formatIntakeData: (data: any) => any = (data: any) => {
  // If data is an array, it's the old format.
  if (Array.isArray(data)) {
    const intakeArray = data
      .map(([key, value]) => {
        try {
          switch (key) {
            case 'q4_address':
              return `Address: ${value.addr_line1}, ${value.addr_line2}, ${value.city}, ${value.state} ${value.postal}`;
            case 'q6_email':
              return `Email: ${value}`;
            case 'q56_howDid':
              return `Referred By: ${Array.isArray(value) ? value.join(', ') : value}`;
            case 'q63_areYou63':
              return `Are you currently sober: ${value}`;
            case 'q67_whenWas':
              return `Sober Date: ${value.month}/${value.day}/${value.year}`;
            case 'q95_whatAre':
              return `What are your substances of choice: ${Array.isArray(value) ? value.join(', ') : value?.other || ''}`;
            case 'q97_areYou':
              return `Are you currently on any medication: ${Object.values(value || {}).join(', ')}`;
            case 'q98_areYou98':
              return `Are you currently in any treatment: ${Array.isArray(value) ? value.join(', ') : value}`;
            case 'q68_doYou68':
              return `Physical Health Goals: ${value}`;
            case 'q70_occupation':
              return `Occupation: ${value}`;
            case 'q71_whatGoals':
              return `Occupational Goals: ${value}`;
            case 'q87_doYou':
              return `12 Step or Other Peer Support Programs: ${value}`;
            case 'q88_ifYou88':
              return `Peer Support Involvement: ${value}`;
            case 'q89_usExercise89':
              return `Do you exercise: ${value}`;
            case 'q73_howMany':
              return `Exercise frequency: ${value}`;
            case 'q90_isMeditation':
              return `Do you meditate: ${value}`;
            case 'q76_howMany76':
              return `Meditation frequency: ${value}`;
            case 'q77_whatDoes':
              return `What does self care look like: ${value}`;
            case 'q79_ifThere':
              return `How do you want to improve self care?: ${value}`;
            case 'q80_areThere80':
              return `Family Dynamic Goals: ${value}`;
            case 'q92_whyDo':
              return `Why do you want to get sober: ${value}`;
            case 'q78_isThere':
              return `Is there anything else we should know: ${value}`;
            case 'q83_accountableEmergency':
              return `Contact: ${value.last}, ${value.first}`;
            case 'q84_accountableEmergency84':
              return `Contact Phone: ${value.full}`;
            case 'q85_doWe85':
              return `Permission to contact with tox: ${value}`;
            case 'q86_doWe86':
              return `Permission to contact with checkin: ${value}`;
            case 'q50_typeA':
              return `Agreement Accepted: ${value}`;
            case 'q104_whatAre104':
              return `Pronouns: ${value}`;
            case 'q105_whatIs':
              return `Sexual Orientation: ${value}`;
            case 'q103_residentialDischarge':
              return `Residential Discharge Date: ${value.month}/${value.day}/${value.year}`;
            case 'q52_signature':
              return null;
            case 'q5_dateOf':
              return `DOB: ${value.month}/${value.day}/${value.year}`;
            case 'q9_phone':
              return `Phone: ${value.full}`;
            case 'q60_whatType':
              return `What type of treatment have you received: ${Array.isArray(value) ? value.join(', ') : value}`;
            default:
              return null;
          }
        } catch (err) {
          console.log('Error processing intake field:', key, err);
          return null;
        }
      })
      .filter(Boolean); // Remove null values

    return intakeArray;
  }

  return data;
};

export default formatIntakeData;
