import React, { useContext, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom';
import Minimal from './layout/Minimal/Minimal';
import Main from './layout/Main/Main';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import ProgramSignUp from './pages/ProgramSignup';
/* import NoteSignUp from './pages/NoteSignUp'; */
import { AuthContext } from './common/context/auth-context';
import ExternalRedirect from './common/UI/ExternalRedirect';

const Dashboard = React.lazy(() => import('./pages/Dashboard')); // lazy load code splitting
const ForgotPassword = React.lazy(() => import('./pages/ForgotPassword')); // lazy load code splitting
const ResetPassword = React.lazy(() => import('./pages/ResetPassword')); // lazy load code splitting
const Account = React.lazy(() => import('./pages/Account')); // lazy load code splitting
const Onboarding = React.lazy(() => import('./pages/Onboarding')); // lazy load code splitting
const Tox = React.lazy(() => import('./pages/Tox')); // lazy load code splitting
const AdminPortal = React.lazy(() => import('./pages/AdminPortal')); // lazy load code splitting
const Goal = React.lazy(() => import('./pages/Goals')); // lazy load code splitting
const Users = React.lazy(() => import('./pages/components/account/users'));
const UserDetails = React.lazy(() => import('./pages/ScheduledTests/UsersScheduledTests'));
const SafetyPlan = React.lazy(() => import('./pages/SafetyPlan'));
const Sms = React.lazy(() => import('./pages/Sms'));
const ScheduleMessages = React.lazy(() => import('./pages/ScheduleMessages'));
const CreateSurvey = React.lazy(() => import('./pages/CreateSurvey'));
const SurveyForm = React.lazy(() => import('./pages/Survey'));
const GoalSurveyForm = React.lazy(() => import('./pages/GoalSurvey'));
const SurveyResults = React.lazy(() => import('./pages/SurveyResponses'));
const VendorAlerts = React.lazy(() => import('./pages/VendorAlerts')); // lazy load code splitting
const GoalLibrary = React.lazy(() => import('./pages/GoalLibrary'));
const MemberReports = React.lazy(() => import('./pages/MemberReports'));
const InsuranceReports = React.lazy(() => import('./pages/InsuranceReports/InsuranceReports'));
const SingleInsuranceReport = React.lazy(
  () => import('./pages/InsuranceReports/SingleInsuranceReport')
);
const Audits = React.lazy(() => import('./pages/Audits/Audits'));
const CoachAudit = React.lazy(() => import('./pages/Audits/CoachAudit'));
const Reinforcement = React.lazy(() => import('./pages/Reinforcement'));
const Analytics = React.lazy(() => import('./pages/Analytics'));
const AccountableGPT = React.lazy(() => import('./pages/AccountableGPT'));
const InsuranceSignUp = React.lazy(() => import('./pages/InsuranceSignUp'));
const Kits = React.lazy(() => import('./pages/Kits'));
const Orders = React.lazy(() => import('./pages/Orders'));
const RekognitionOverview = React.lazy(() => import('./pages/Rekognition/RekognitionOverview'));
const RekognitionFaceSearchJob = React.lazy(
  () => import('./pages/Rekognition/RekognitionFaceSearchJob')
);
const ToxReviews = React.lazy(() => import('./pages/ToxReviews/ToxReviews'));
const Invoices = React.lazy(() => import('./pages/Invoices/Invoices'));
const StripeInvoiceDetails = React.lazy(() => import('./pages/Invoices/StripeInvoiceDetails'));
const InvoiceDetails = React.lazy(() => import('./pages/Invoices/InvoiceDetails'));
const UpcomingStripeInvoiceDetails = React.lazy(
  () => import('./pages/Invoices/UpcomingStripeInvoiceDetails')
);
const GehaEligibility = React.lazy(() => import('./pages/GEHA/GehaEligibility'));
const ScheduledTests = React.lazy(() => import('./pages/ScheduledTests/ScheduledTests'));

const loggedOutDirects = [
  'sign-in',
  'sign-up',
  'program-sign-up',
  'insurance',
  'ForgotPassword',
  'resetpw',
  'survey',
  'goalsurvey',
  'surveyresults',
  'reinforcement'
];

const excludedRoutes = ['surveyresults', 'survey', 'reinforcement', 'goalsurvey', 'resetpw'];

const AppRoutes = () => {
  const auth = useContext(AuthContext);
  let routes;
  const location = useLocation();
  const navigate = useNavigate();
  const [currentPath, setCurrentPath] = React.useState('');
  const { search } = location; // confirm email
  const params = new URLSearchParams(search);
  const key = params.get('key');
  const redirect = params.get('redirect');
  let dashboard = '/dashboard';
  if (key) dashboard = `/dashboard?key=${key}`; // send confirmation key to dashboard if logged in.
  let tenantName = '';
  if ((auth.program || auth.programProvider) && auth.tenant) tenantName = auth.tenant;
  else if (auth.provider && auth.email) tenantName = auth.email;
  else if (auth.tenant) tenantName = auth.tenant;

  const setCurrentPathCallback = React.useCallback((page: string) => {
    setCurrentPath(page);
  }, []);

  useEffect(() => {
    const redirectToURL = (page: string) => {
      setCurrentPathCallback(page);
      navigate(`/${page}`);
    };

    const newPath = location?.pathname?.substring(1);
    if (!excludedRoutes.includes(newPath)) {
      if (!auth.isLoggedIn) {
        if (loggedOutDirects.includes(newPath)) {
          if (redirect && redirect !== '') {
            redirectToURL(newPath + '?redirect=' + redirect);
          } else {
            redirectToURL(newPath);
          }
        } else {
          redirectToURL('sign-in?redirect=' + newPath);
        }
      }
      if (auth.isLoggedIn) {
        if (
          newPath === 'sign-in' ||
          newPath === 'sign-up' ||
          newPath === 'program-sign-up' ||
          newPath === 'insurance'
        ) {
          redirectToURL('dashboard');
        } else if (redirect && !loggedOutDirects.includes(newPath) && redirect !== '') {
          redirectToURL(redirect);
        }
      }
    }
  }, [
    auth.isLoggedIn,
    currentPath,
    location?.pathname,
    redirect,
    navigate,
    setCurrentPathCallback
  ]);

  if (auth.stripeStatus && auth.stripeStatus.status === 'canceled' && auth.isLoggedIn) {
    routes = (
      <Routes>
        <Route
          path="/account"
          element={
            <Main tenantname={auth.tenant}>
              <Account />
            </Main>
          }
        />
        <Route path="/" element={<Navigate to="/account?billing=true" replace />} />
      </Routes>
    );
  } else if (auth.isLoggedIn) {
    routes = (
      <Routes>
        <Route
          path="/dashboard"
          element={
            <Main tenantname={tenantName}>
              <Dashboard />
            </Main>
          }
        />
        <Route
          path="/account"
          element={
            <Main tenantname={tenantName}>
              <Account />
            </Main>
          }
        />
        <Route
          path="/tox"
          element={
            <Main tenantname={tenantName}>
              <Tox />
            </Main>
          }
        />
        <Route
          path="/vendoralerts"
          element={
            <Main tenantname={tenantName}>
              <VendorAlerts />
            </Main>
          }
        />
        <Route
          path="/goal"
          element={
            <Main tenantname={tenantName}>
              <Goal />
            </Main>
          }
        />
        <Route
          path="/accountableGPT"
          element={
            <Main tenantname={tenantName}>
              <AccountableGPT />
            </Main>
          }
        />
        <Route
          path="/adminportal"
          element={
            <Main tenantname={tenantName}>
              <AdminPortal />
            </Main>
          }
        />
        <Route
          path="/users"
          element={
            <Main tenantname={tenantName}>
              <Users />
            </Main>
          }
        />
        <Route
          path="/scheduled-tests/users"
          element={
            <Main tenantname={tenantName}>
              <ScheduledTests />
            </Main>
          }
        />
        <Route
          path="/scheduled-tests/users/:userId"
          element={
            <Main tenantname={tenantName}>
              <UserDetails />
            </Main>
          }
        />
        <Route
          path="/safety"
          element={
            <Main tenantname={tenantName}>
              <SafetyPlan />
            </Main>
          }
        />
        <Route
          path="/sms"
          element={
            <Main tenantname={tenantName}>
              <Sms />
            </Main>
          }
        />
        <Route
          path="/schedule"
          element={
            <Main tenantname={tenantName}>
              <ScheduleMessages />
            </Main>
          }
        />
        <Route
          path="/surveyadmin"
          element={
            <Main tenantname={tenantName}>
              <CreateSurvey />
            </Main>
          }
        />
        <Route
          path="/goallibrary"
          element={
            <Main tenantname={tenantName}>
              <GoalLibrary />
            </Main>
          }
        />
        <Route
          path="/survey"
          element={
            <Minimal>
              <SurveyForm />
            </Minimal>
          }
        />
        <Route
          path="/goalsurvey"
          element={
            <Minimal>
              <GoalSurveyForm />
            </Minimal>
          }
        />
        <Route
          path="/surveyresults"
          element={
            <Main tenantname={tenantName}>
              <SurveyResults />
            </Main>
          }
        />
        <Route
          path="/reports"
          element={
            <Main tenantname={tenantName}>
              <MemberReports />
            </Main>
          }
        />
        <Route
          path="/insurance-reports"
          element={
            <Main tenantname={tenantName}>
              <InsuranceReports />
            </Main>
          }
        />
        <Route
          path="/insurance-reports/:id"
          element={
            <Main tenantname={tenantName}>
              <SingleInsuranceReport />
            </Main>
          }
        />
        <Route
          path="/analytics"
          element={
            <Main tenantname={tenantName}>
              <Analytics />
            </Main>
          }
        />
        <Route
          path="/onboarding"
          element={
            <Main tenantname={tenantName}>
              <Onboarding />
            </Main>
          }
        />
        <Route
          path="/reinforcement"
          element={
            <Minimal>
              <Reinforcement />
            </Minimal>
          }
        />
        <Route
          path="/kits"
          element={
            <Main tenantname={tenantName}>
              <Kits />
            </Main>
          }
        />
        <Route
          path="/orders"
          element={
            <Main tenantname={tenantName}>
              <Orders />
            </Main>
          }
        />
        <Route
          path="/audits"
          element={
            <Main tenantname={tenantName}>
              <Audits />
            </Main>
          }
        />
        <Route
          path="/audits/:id"
          element={
            <Main tenantname={tenantName}>
              <CoachAudit />
            </Main>
          }
        />
        <Route
          path="/rekognition"
          element={
            <Main tenantname={tenantName}>
              <RekognitionOverview />
            </Main>
          }
        />
        <Route
          path="/rekognition/face-search-jobs/:id"
          element={
            <Main tenantname={tenantName}>
              <RekognitionFaceSearchJob />
            </Main>
          }
        />
        <Route
          path="/tox-reviews"
          element={
            <Main tenantname={tenantName}>
              <ToxReviews />
            </Main>
          }
        />
        <Route
          path="/program-invoices"
          element={
            <Main tenantname={tenantName}>
              <Invoices />
            </Main>
          }
        />
        <Route
          path="/program-invoices/:programId"
          element={
            <Main tenantname={tenantName}>
              <InvoiceDetails />
            </Main>
          }
        />
        <Route
          path="/program-invoices/:programId/invoice/:stripeInvoiceId"
          element={
            <Main tenantname={tenantName}>
              <StripeInvoiceDetails />
            </Main>
          }
        />
        <Route
          path="/program-invoices/:programId/upcoming"
          element={
            <Main tenantname={tenantName}>
              <UpcomingStripeInvoiceDetails />
            </Main>
          }
        />
        <Route
          path="/geha-eligibility"
          element={
            <Main tenantname={tenantName}>
              <GehaEligibility />
            </Main>
          }
        />
        <Route path="/website" element={<ExternalRedirect to="https://youareaccountable.com/" />} />
        <Route
          path="/scholarships"
          element={<ExternalRedirect to="https://youareaccountable.com/scholarships/" />}
        />
        <Route
          path="/resetpw"
          element={
            <Minimal>
              <ResetPassword />
            </Minimal>
          }
        />
        <Route path="/" element={<Navigate to={dashboard} replace />} />
      </Routes>
    );
  } else {
    routes = (
      <Routes>
        <Route
          path="/sign-in"
          element={
            <Minimal>
              <SignIn />
            </Minimal>
          }
        />
        <Route
          path="/sign-up"
          element={
            <Minimal>
              <SignUp />
            </Minimal>
          }
        />
        <Route
          path="/program-sign-up"
          element={
            <Minimal>
              <ProgramSignUp />
            </Minimal>
          }
        />
        <Route
          path="/insurance"
          element={
            <Minimal>
              <InsuranceSignUp />
            </Minimal>
          }
        />
        {/* <Route path = "/note-sign-up"  render={(props)=> <Minimal><NoteSignUp {...props}/></Minimal>}/> */}
        <Route
          path="/ForgotPassword"
          element={
            <Minimal>
              <ForgotPassword />
            </Minimal>
          }
        />
        <Route
          path="/resetpw"
          element={
            <Minimal>
              <ResetPassword />
            </Minimal>
          }
        />
        <Route
          path="/survey"
          element={
            <Minimal>
              <SurveyForm />
            </Minimal>
          }
        />
        <Route
          path="/goalsurvey"
          element={
            <Minimal>
              <GoalSurveyForm />
            </Minimal>
          }
        />
        <Route
          path="/surveyresults"
          element={
            <Minimal>
              <SurveyResults />
            </Minimal>
          }
        />
        <Route
          path="/reinforcement"
          element={
            <Minimal>
              <Reinforcement />
            </Minimal>
          }
        />
        <Route
          path="/terms"
          element={<ExternalRedirect to="https://youareaccountable.com/terms" />}
        />
        <Route
          path="/programterms"
          element={
            <ExternalRedirect to="https://youareaccountable.com/terms-of-service-partner-program" />
          }
        />
        <Route path="/website" element={<ExternalRedirect to="https://youareaccountable.com/" />} />
        <Route
          path="/scholarships"
          element={<ExternalRedirect to="https://youareaccountable.com/scholarships/" />}
        />
        <Route path="/" element={<Navigate to="/sign-in" replace />} />
      </Routes>
    );
  }
  return routes;
};

export default AppRoutes;
